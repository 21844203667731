<template>
  <div class="home">
    <Header title="Une meilleure façon de vivre son cancer." />
    <Navbar />
    <div class="loader" v-if="!user">
      <Spinner />
    </div>
    <EmptyState
      v-else-if="user.events.length == 0 || user.agenda.length == 0"
    />

    <div class="container" v-else>
      <div class="container__desktop">
        <div>
          <div class="home__profil">
            <div class="home__profil-img">
              <img :src="user.data.img" alt="#" v-if="user.data.img" />
              <img src="../assets/default.jpg" alt="#" v-else />
            </div>
            <div class="home__profil-name">
              <p>Hello {{ user.data.pseudo }},</p>
            </div>
          </div>
          <div class="card__conseil">
            <h4>Voici nos conseils pour votre traitement du moment</h4>
            {{ conseil }}
          </div>
        </div>
        <div class="card__calendar">
          <Calendar :attributes="events" is-expanded />
        </div>
      </div>

      <h3>Progression</h3>
      <div class="card__container">
        <div
          class="card__progression"
          v-for="event in events"
          :key="event.type"
          @click="eventHandler(event)"
        >
          <h4>{{ event.type }}</h4>

          <img
            :src="getImgUrl(event.type)"
            alt="#"
            class="card__progression-img"
          />

          <div class="card__progression-mark">
            <!-- Marker in progress -->
            <div
              class="timeline__marker timeline__marker--progress home__marker"
              v-if="remainingDays(event.dateFin) > 0"
            >
              <svg viewBox="0 0 12 13">
                <g transform="translate(-48 -31.835)">
                  <ellipse
                    cx="1.5"
                    cy="2"
                    rx="1.5"
                    ry="2"
                    transform="translate(53 31.835)"
                    fill="#fff"
                  />
                  <circle
                    cx="1.5"
                    cy="1.5"
                    r="1.5"
                    transform="translate(48 37.835)"
                    fill="#fff"
                  />
                  <ellipse
                    cy="0.5"
                    ry="0.5"
                    transform="translate(58 34.835)"
                    fill="#fff"
                  />
                  <path
                    d="M95.788,92.995a1.579,1.579,0,0,0-2.248,0,1.616,1.616,0,0,0,0,2.269,1.579,1.579,0,0,0,2.248,0A1.616,1.616,0,0,0,95.788,92.995Z"
                    transform="translate(-43.67 -58.772)"
                    fill="#fff"
                  />
                  <ellipse
                    cx="0.5"
                    cy="1"
                    rx="0.5"
                    ry="1"
                    transform="translate(59 37.835)"
                    fill="#fff"
                  />
                  <ellipse
                    cx="1"
                    cy="0.5"
                    rx="1"
                    ry="0.5"
                    transform="translate(57 41.835)"
                    fill="#fff"
                  />
                  <ellipse
                    cx="1.5"
                    cy="1"
                    rx="1.5"
                    ry="1"
                    transform="translate(53 42.835)"
                    fill="#fff"
                  />
                  <ellipse
                    cx="1"
                    cy="1.5"
                    rx="1"
                    ry="1.5"
                    transform="translate(50 40.835)"
                    fill="#fff"
                  />
                </g>
              </svg>
            </div>
            <!-- Marker in done -->
            <div class="timeline__marker home__marker" v-else>
              <svg viewBox="0 0 14.054 10.222">
                <path
                  d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1.022,1.022,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16a1,1,0,0,0,0-1.47Z"
                  transform="translate(-4.978 -6.914)"
                  fill="#fff"
                />
              </svg>
            </div>
          </div>

          <div class="card__progression-status">
            <p>
              {{
                remainingDays(event.dateFin) > 0
                  ? `${remainingDays(event.dateFin)} jours`
                  : "Terminé"
              }}
            </p>
          </div>

          <div class="card__progression-wave">
            <img
              src="../assets/progress_wave.png"
              alt="a"
              v-if="remainingDays(event.dateFin) > 0"
            />

            <img src="../assets/done_wave.png" alt="a" v-else />
          </div>
        </div>
      </div>
      <div class="container__mobile">
        <h3>Rendez-vous</h3>
        <div class="card__container card__container--agenda">
          <Card
            v-for="rdv in user.agenda"
            :key="rdv.id"
            :event="rdv"
            :isRDV="true"
            @clicked="agendaHandler(rdv)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "../components/Card.vue";
import Navbar from "../components/Navbar.vue";
import Spinner from "../components/Spinner.vue";
import Header from "../components/Header.vue";
import EmptyState from "../components/EmptyState.vue";
import Calendar from "v-calendar/lib/components/calendar.umd";

export default {
  components: { Spinner, Navbar, Card, Header, EmptyState, Calendar },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    conseil() {
      const events = this.user.events;
      const event = events[events.length - 1];
      const conseils = this.$store.state.conseils;

      const conseilsOfEvent = conseils.filter(
        el => el.name.toLowerCase() == event.type.split(" ")[0].toLowerCase()
      );

      const randomConseil =
        conseilsOfEvent[0].data[
          Math.floor(Math.random() * conseilsOfEvent[0].data.length)
        ];

      return randomConseil;
    },
    events() {
      if (window.innerWidth > 850) {
        return this.user.events.slice(-3);
      }
      return this.user.events.slice(-2);
    }
  },
  methods: {
    getImgUrl(pic) {
      // Get the images
      switch (pic) {
        case "Découverte de la maladie":
          return require("../assets/progression/decouverte.svg");
        case "Chimiothérapie taxotere":
          return require("../assets/progression/chimiotherapie_taxotere.svg");
        case "Chimiothérapie epirubicine":
          return require("../assets/progression/chimioterapie_epirubicine.svg");
        case "Radiothérapie":
          return require("../assets/progression/radiotherapie.svg");
        case "Chimiothérapie xeloda":
          return require("../assets/progression/chimioterapie_xeloda.svg");
        case "Chirurgie":
          return require("../assets/progression/reconstruction.svg");
        case "1 an de traitement":
          return require("../assets/progression/1an_de_traitement.svg");
      }
    },
    remainingDays(date) {
      const date1Array = new Date()
        .toJSON()
        .slice(0, 10)
        .split("-")
        .reverse();
      const date2Array = date.split("-");
      const date1 = new Date(date1Array[2], date1Array[1] - 1, date1Array[0]);
      const date2 = new Date(date2Array[0], date2Array[1] - 1, date2Array[2]);
      console.log(date2Array);
      const one_day = 1000 * 60 * 60 * 24;
      return Math.ceil((date2.getTime() - date1.getTime()) / one_day);
    },
    agendaHandler(event) {
      this.$store.dispatch("popupAgendaOpen", event);
    },
    eventHandler(event) {
      this.$store.dispatch("popupEventOpen", event);
    }
  }
};
</script>
<style lang="scss">
.container {
  width: 80%;
  margin: auto;
}
h3 {
  text-transform: uppercase;
  color: #3b5150;
  text-align: left;
}
.home {
  display: flex;
  flex-direction: column;
  &__desktop {
    width: 95%;
    margin: auto;
  }

  &__profil {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    column-gap: 20px;

    &-img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
    }
    &-name {
      color: #23233c;
      font-size: 24px;
      font-family: "Lora", serif;
      padding-bottom: 10px;
      font-weight: 600;
    }

    img {
      width: 100%;
      height: auto;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }
}

.card__container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  column-gap: 20px;
  cursor: pointer;
  &--agenda {
    flex-direction: column;
    text-align: left;
  }
}

.card {
  &__conseil {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    margin: 20px 0px;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.13);
    h4 {
      color: #95cdcb;
      font-size: 20px;
      font-family: "Lora", serif;
      padding-bottom: 10px;
    }
  }
  &__progression {
    position: relative;
    background-color: #fff;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.13);
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    height: 230px;
    width: 200px;
    &-img {
      height: 100px;
      width: 100px;
      z-index: 2;
    }
    h4 {
      font-size: 15px;
      margin: auto;
      font-weight: 500;
      color: #95cdcb;
      margin-bottom: 20px;
      z-index: 2;
    }
    p {
      text-align: center;
      padding: 10px;
      z-index: 2;
    }
    &-status {
      position: absolute;
      right: 0px;
      bottom: 0px;
      z-index: 2;
    }
    &-wave {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 80%;
      opacity: 0.7;
      display: table;
      z-index: 1;

      img {
        display: table-cell;
        width: 100%;
      }
    }
  }
  &__calendar {
    display: none;
  }
}
.container {
  &__mobile {
    display: block;
  }
}

.timeline {
  &__marker {
    background-color: #5e8584;
    background-color: #5e8584;
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;

    z-index: 3;

    &--progress {
      background-color: #fad1d1;
    }
  }
  svg {
    width: 14px;
  }
}
@media (min-width: 850px) {
  .home {
    width: 100%;
    margin: auto;
  }
  h3 {
    margin: 20px 0px;
    font-size: 25px;
  }
  .card {
    &__calendar {
      display: block;
      width: 45%;
    }
    &__conseil {
      text-align: left;
      width: 30em;
      margin: 20px 0px 0px 0px;
      h4 {
        width: 17em;
        font-size: 22px;
        padding-bottom: 20px;
      }
    }
    &__container {
      justify-content: flex-end;

      column-gap: 40px;
    }
    h4 {
      font-size: 25px;
    }
    &__progression {
      width: 30%;
      height: 175px;

      h4 {
        font-size: 17px;
        font-weight: 300;
        text-transform: uppercase;
      }
      &-img {
        width: 40%;
        max-height: 70%;
      }
      &-wave {
        img {
          width: 50%;
        }
      }
    }
  }
  .container {
    padding: 0px 0px 0px 60px;
    width: 90%;
    &__desktop {
      display: flex;
      flex-wrap: nowrap;
      align-content: space-between;
      justify-content: flex-start;
      align-items: flex-end;
      column-gap: 40px;
    }
    &__mobile {
      display: none;
    }
  }
  .vc-container {
    --red-600: #95cdcb !important;
    border-color: #fff;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.13);
    border-radius: 5px;
  }
  .vc-header {
    height: 70px;
    position: relative;
    margin-bottom: 20px;

    &::before {
      content: "";
      width: 100%;
      height: 1px;
      background-color: #e1e3eb;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .vc-arrows-container {
    top: 40px !important;
    transform: translateY(-50%);
  }
  .vc-svg-icon path {
    fill: #95cdcb !important;
  }
  .vc-title {
    text-transform: uppercase;
    font-size: 16px !important;
    color: #5d6770 !important;
    font-weight: normal !important;
  }
}
.home {
  &__marker {
    bottom: 33px;
    right: 30px;
  }
}
</style>
